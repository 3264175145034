
import { defineComponent } from "vue";
import Card from "primevue/card";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import CustomAccordion from "@/components/UI/CustomAccordion.vue";
import AccordionTab from "primevue/accordiontab";
import Utils from "@/utility/utils";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Tracking",
  components: {
    Card,
    Divider,
    InputText,
    Calendar,
    CustomAccordion,
    AccordionTab,
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
    }),
  },
  data: () => ({
    currentOrder: {} as any,
    currentId: "",
  }),

  created() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
  mounted() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
  methods: {
    getShipChargeTotal(list: any) {
      return Utils.reduceTotal(list, "pkgCharge");
    },
  },
});
